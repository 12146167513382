import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const Container = styled.div`
  width: ${props => props.containerWidth || 'auto'};
  height: ${props => props.containerHeight || 'auto'};
  margin: ${props => props.containerMargin || '0 auto'};

  ${MEDIA.PHONE`
    margin: 0 auto;
  `};

  ${MEDIA.TABLET`
    margin: 0 auto;
    ${props => !props.noPadding && 'padding: 2rem;'}
    width: auto;
  `};

  ${MEDIA.MIN_TABLET`
    ${props => !props.noPadding && 'padding: 0 2rem;'}
  `};

  ${MEDIA.MIN_LG_DESKTOP`
    ${props => !props.noPadding && 'padding: 0;'}
  `};
`;

const Box = ({
  children,
  containerWidth,
  containerHeight,
  containerMargin,
  ...props
}) => (
  <Container
    containerWidth={containerWidth}
    containerHeight={containerHeight}
    containerMargin={containerMargin}
    {...props}
  >
    {children}
  </Container>
);

Box.propTypes = {
  children: PropTypes.node.isRequired,
  containerWidth: PropTypes.string,
  containerHeight: PropTypes.string,
  containerMargin: PropTypes.string,
};

export default Box;
