import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Media = ({ fluid = false, fixed = false, alt = '', ...props }) => {
  // console.log('in media', fluid, fixed);
  return (
    <>
      <Img fluid={fluid && fluid} fixed={fixed && fixed} alt={alt} {...props} />
    </>
  );
};

Media.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fluid: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  fixed: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  alt: PropTypes.string,
};

export default Media;
