import styled from 'styled-components';
// import MEDIA from 'helpers/mediaTemplates';
import { buttonTransition } from 'constants/transition';
import { colors } from 'constants/theme';

export const ButtonContainer = styled.span`
  display: inline-block;
  width: ${props => props.buttonWidth || 'auto'};

  .btn {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: ${props => props.buttonWidth || 'auto'};

    &-solid {
      text-decoration: none;
      text-transform: uppercase;

      font: ${({ size }) => {
        switch (size) {
          case 'sm':
            return `0.8rem/1.4rem 'Archivo Black';`; // eslint-disable-line quotes
          default:
          case 'md':
            return `1rem/1.6rem 'Archivo Black';`; // eslint-disable-line quotes
          case 'lg':
            return `1.6rem/2rem 'Archivo Black';`; // eslint-disable-line quotes
        }
      }}
      
      padding: ${({ size }) => {
        switch (size) {
          case 'sm':
            return '0.25rem 0.5rem;';
          default:
          case 'md':
            return '0.5rem 1rem;';
          case 'lg':
            return '1rem 1.25rem;';
        }
      }}

      border-width ${({ size }) => {
        switch (size) {
          case 'lg':
            return '4px';
          default:
            return '2px;';
        }
      }};
      border-style: solid;

      transition: background ${buttonTransition} ease-in-out,
        color ${buttonTransition} ease-in-out;
    }

    &-outline {
      background-color: transparent;
    }
    
    &-link {
      
    }

    &-trace_black {
      background-color: ${colors.trace_black};
      border-color: ${colors.trace_black};
      color: ${colors.white};

      &:hover {
        background-color: transparent;
        color: ${colors.trace_black}
      }
    }

    &-cool_grey {
      background-color: ${colors.cool_grey};
      border-color: ${colors.cool_grey};
      color: ${colors.white};

      &:hover {
        background-color: transparent;
        color: ${colors.cool_grey}
      }
    }

    &-warm_grey {
      background-color: ${colors.warm_grey};
      border-color: ${colors.warm_grey};
      color: ${colors.cool_grey};

      &:hover {
        background-color: transparent;
        color: ${colors.warm_grey}
      }
    }

    &-collective_red {
      background-color: ${colors.collective_red};
      border-color: ${colors.collective_red};
      color: ${colors.warm_grey};

      &:hover {
        background-color: transparent;
        color: ${colors.collective_red}
      }
    }

    &-dusty_gold {
      background-color: ${colors.dusty_gold};
      border-color: ${colors.dusty_gold};
      color: ${colors.white};

      &:hover {
        background-color: transparent;
        color: ${colors.dusty_gold}
      }
    }
  }
}

`;
