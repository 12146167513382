import React from 'react';
import PropTypes from 'prop-types';

import Title from '~v2/elements/title';
import Button from '~v2/elements/button';

const Content = ({ children, header = false, button = false }) => {
  return (
    <div>
      {header && <Title {...header}>{header.text}</Title>}

      {children}

      {button && <Button {...button}>{button.text}</Button>}
    </div>
  );
};

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  header: PropTypes.oneOfType([
    PropTypes.shape({
      as: PropTypes.string,
      variant: PropTypes.string,
      text: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  button: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.string,
      color: PropTypes.string,
      text: PropTypes.string,
      to: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
};

export default Content;
