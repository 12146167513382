import React from 'react';
import PropTypes from 'prop-types';
import { invert } from 'underscore';
import { Link } from 'gatsby';

import { colors } from 'constants/theme';

import { ButtonContainer } from './button.css';

const Button = ({
  children,
  color = colors.cool_grey,
  type = 'solid',
  buttonRole,
  to,
  externalLink = false,
  size = 'md',
  buttonWidth,
}) => {
  const colorClass = invert(colors)[color];

  // console.log('Button Component:', color, colorClass, type, to, size);

  return (
    <ButtonContainer size={size} type={type} buttonWidth={buttonWidth}>
      {to ? (
        <>
          {externalLink ? (
            <a
              href={to}
              className={`btn btn-${type} btn-${colorClass} btn-${size}`}
            >
              {children}
            </a>
          ) : (
            <Link
              to={to}
              className={`btn btn-${type} btn-${colorClass} btn-${size}`}
            >
              {children}
            </Link>
          )}
        </>
      ) : (
        <button
          type={buttonRole}
          className={`btn btn-${type} btn-${colorClass} btn-${size}`}
        >
          {children}
        </button>
      )}
    </ButtonContainer>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  type: PropTypes.string,
  buttonRole: PropTypes.string,
  to: PropTypes.string,
  externalLink: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  buttonWidth: PropTypes.string,
};

export default Button;
